import React from 'react'
import * as bulma from "reactbulma";
import StocksList from "./StocksList.jsx";
import ShortsGraph from "./ShortsGraph.jsx";
import PriceGraph from "./PriceGraph.jsx";
import StocksLoaderStatus from "./StocksLoaderStatus.jsx";

const stocksUrl2 = 'https://server.csure.me:3033/getStocks';

const CommSecURL = 'https://server.csure.me:3033/getCommsecStocks';

//const CommSecURL = 'http://localhost:4444/getCommsecStocks';


class Dashboard extends React.Component {

  state = {
  // stocks = {name: {current_value: 12, history: [{time: '2131', value: 45}, ...], is_selected: false}, ...}
   skey : 0,
   pkey : 10000,
   stocks: {},
   stock_order: [],
   refreshed: [],
   total_change: 0,
   total_pct: 0,
   total_val: 0,
   avg_shorts: 0,
   avg_volume: 0,
   total_profit_pct: 0,
   total_profit: 0,
   selected_stock: null,
   connectionError: false,
   loadAll: false
  }

  calcaverages = () =>
  {
    let stocks=this.state.stocks
    let SAMPLESIZE = 21
    Object.keys(stocks).forEach(function(stock_name)
    {
        let ttl_volume = 0;
        let ttl_shorts = 0;
        let ttl_short_pos = 0;
        for (var i = stocks[stock_name].history.length  ; i > (stocks[stock_name].history.length - SAMPLESIZE) && i >= 0; i--)
        {

             ttl_volume += stocks[stock_name].history[i-1].volume;
             ttl_shorts += stocks[stock_name].history[i-1].shorts;
             ttl_short_pos += stocks[stock_name].history[i-1].short_pos;

        }

        if ( stocks[stock_name].history.length > SAMPLESIZE)
            i = SAMPLESIZE
        else
          i = stocks[stock_name].history.length - i

        stocks[stock_name].avg_volume = Math.round(ttl_volume / i);
        stocks[stock_name].avg_shorts = ttl_shorts / i;
        stocks[stock_name].avg_short_pos = ttl_short_pos / i;

        // if volume is 20% up on usual volumes, mark the stock as "hot"
        var d = new Date();
        var h = d.getHours();
        var m = d.getMinutes()
        var mult = 0;

        if (m > 30) {h = h+0.5}

        //first hour higher volume so round up...
        if ((h < 11)&& (h>10))
          { h = 11 }

        // Stock markets open 10 and close at 4. Don't count first hour...
        if ((h<= 11)||(h> 16)) {mult = 1}
          else {mult = 5.8/(h-10)}



        //if the volumes are up 30% on average volumes mark as hot
        var pctup = (mult * stocks[stock_name].current_volume  /  stocks[stock_name].avg_volume) - 1
        if (pctup > 0.3) {stocks[stock_name].hot = true}
        else
          {stocks[stock_name].hot = false}

        stocks[stock_name].pct_avg_volume = pctup


        console.log("Hours = " + h)

// last record loaded often doesn't have the short data loaded yet. Volumes taken from short data feeds so best way to judge
        if ((stocks[stock_name].current_shorts === null) ||  (stocks[stock_name].current_shorts === 0))
        {
            stocks[stock_name].current_shorts = stocks[stock_name].history[stocks[stock_name].history.length-2].shorts ;
            stocks[stock_name].current_short_pos = stocks[stock_name].history[stocks[stock_name].history.length-2].short_pos;
            stocks[stock_name].current_volume = stocks[stock_name].history[stocks[stock_name].history.length-2].volume;
        }


    })

  }
  loaddbresults=(results) =>
  {
    this.props.hideSpinner();
    console.log("Loading DB results");

  // time stored in histories should be consisitent across stocks(better for graphs)
    let current_time = Date.now
    this.state.stocks = {};

    let new_stocks = this.state.stocks;
    let shares_issued = null;
    let short_pos = 0;
    let wtd_volume = 0;
    let shorts = 0;
    let cur_stock="";

    results.map((stock, index,results) =>
    {
      let dt = new Date(stock.date +'T00:00:00');
       if (stock.shares_issued !== null)  {  shares_issued = Number(stock.shares_issued)}
      // if the first entry doesnt have shares_issued, iterate through to find
      if (isNaN( shares_issued))
      {
        for (var i = 0; i < results.length; i++) {
          if (!isNaN(results[i].shares_issued))
            {
              shares_issued = Number(results[i].shares_issued);
              console.log("stock : " + stock.share + " Issued = " +  shares_issued )
              break;
            }
          }
      }

      if (( typeof(stock.short_pos) === "number" ) ) {short_pos = Number(stock.short_pos) / shares_issued * 100}
      if ((typeof(stock.volume) === "number") && stock.volume > 0)
      {
        shorts = Number(stock.shorts) / stock.volume * 100;
        wtd_volume = stock.volume / shares_issued * 100;
      }
      else shorts = 0

      if (dt instanceof Date && isFinite(dt))
      {
          // stock = ['name', 'value']
          if(this.state.stocks[stock.share])
          {

            new_stocks[stock.share].history.push({date : dt, volume: Number(stock.volume),wtd_volume : wtd_volume , shorts: shorts, value: Number(stock.close), short_pos: short_pos})
            new_stocks[stock.share].current_value = Number(stock.close);
            new_stocks[stock.share].current_volume = Number(stock.volume);
            new_stocks[stock.share].current_shorts = shorts;
            new_stocks[stock.share].current_short_pos = short_pos;

          }
          else
          {
            new_stocks[stock.share] = { current_value: stock.close, current_shorts: shorts,current_volume: stock.volume, current_short_pos: short_pos, shares_issued : shares_issued, history: [{date : dt, shorts: shorts, volume:Number(stock.volume),wtd_volume : wtd_volume , value: Number(stock.close), short_pos: short_pos}], is_selected: false }
            new_stocks[stock.share].changepct = 0;
            new_stocks[stock.share].current_value = 0;
            new_stocks[stock.share].changeamt = 0;
            new_stocks[stock.share].units = 0;
            new_stocks[stock.share].announcement = false;
            new_stocks[stock.share].profitamt = 0;
            new_stocks[stock.share].profitpct = 0;
            new_stocks[stock.share].holdings = 0;
            new_stocks[stock.share].purchaseprice = 0;

          }
      }
    })

    setTimeout(this.componentDidMount,100600)
  }

  loadcommsec=() =>
  {

    console.log("Loading commsec")
    fetch(CommSecURL)
    .then(res => {
  //    console.log(res);
      return res.json() } )

    .then((results) =>

    {

    // load CommSec data into current values
        let new_stocks = this.state.stocks;
        let ttl_val = 0;
        let ttl_change = 0;
        let ttl_profit = 0;
        let sort_order = new Array(100);

        Object.keys(results).forEach(function(key)
        {
          let stock = results[key];

          if (!(key in new_stocks))
          {
            new_stocks[key] =  { current_value: stock.price, current_shorts: 0,current_volume: 0, current_short_pos: 0, shares_issued : 0, history: [{date : Date.now, shorts: 0, volume:0, value: Number(stock.price), short_pos: 0}], is_selected: false }
          }

          sort_order[stock.order]= key;
          new_stocks[key].changepct = Number(stock.changepct);
          new_stocks[key].current_value = Number(stock.price);
          new_stocks[key].current_volume = Number(stock.volume);

          new_stocks[key].name = stock.name.replace("LIMITED","");
          new_stocks[key].changeamt = Number(stock.changeamt);
          new_stocks[key].units = Number(stock.units);
          new_stocks[key].announcement = stock.announcement;
          new_stocks[key].profitamt = stock.profitamt;
          new_stocks[key].profitpct = stock.profitpct*100;
          new_stocks[key].holdings = stock.units * stock.price;
          new_stocks[key].purchaseprice = stock.purchaseprice

          ttl_val = ttl_val+ (stock.price * stock.units);
          ttl_change = ttl_change + (stock.changeamt);
          ttl_profit = ttl_profit + stock.profitamt



        })
        this.state.total_val = (ttl_val);
        this.state.total_change = ttl_change;
        this.state.total_pct = ttl_change / (ttl_val - ttl_change)*100;
        this.state.total_profit=ttl_profit;
        this.state.total_profit_pct = ttl_profit / (ttl_val - ttl_profit)*100;


        this.state.refreshed = new Date();
        this.state.stock_order = sort_order

        console.log("Loaded commsec data. Stock data dump:")
        console.log(this.state)
        this.setState({stocks: new_stocks});
        if (this.state.selected_stock)
        {
           this.toggleStockSelection(this.state.selected_stock)
        }
        setTimeout(this.loadcommsec,30000)


      })
  }

  loadData = () =>
  {
    if (this.state.loadAll) {
      var url = stocksUrl2 + "?loadAll=true";
      console.log("Loading all data") }
    else {
       var url = stocksUrl2
    }
    fetch(url)
    .then(res => {
          console.log(res);
          return res.json()  } )

    .then((results) =>
    {
      this.loaddbresults(results);
      this.loadcommsec()
      this.calcaverages()
    })

    .catch(error =>  {
          console.error('There has been a problem fetching share data from the database:', error);
        });

  }

  loadAll = () =>
  {
    if (! this.state.loadAll) {
      this.state.loadAll = true
      this.loadData()
    }
  }
  componentDidMount = () =>
  {
    console.log("Component did mount")
    this.loadData()

  }


  toggleStockSelection = (stock_name) => {
    let new_stocks = this.state.stocks;

    Object.keys(new_stocks).map((stock_name) => { new_stocks[stock_name].is_selected = false});
 //   new_stocks[stock_name].is_selected = !new_stocks[stock_name].is_selected;
    new_stocks[stock_name].is_selected = true;
    this.state.selected_stock = stock_name;
    this.setState({ stocks: new_stocks })
  }

  resetData = () => {
    let new_stocks = this.state.stocks;
    Object.keys(this.state.stocks).map((stock_name, index) =>
    {
      new_stocks[stock_name].history = [new_stocks[stock_name].history.pop()];
    });
    this.setState({ stocks: new_stocks });
  }

  areStocksLoaded = () => {
    return Object.keys(this.state.stocks).length > 0;
  }

  resetCharts = () => {
    this.state.pkey++;
    this.state.skey++;
    this.toggleStockSelection(this.state.selected_stock)
  }

  reload = () => {
    window.location.reload()
  }

  render()
  {
    console.log( this.state)
     return (

      <div className='container'>
        <div className='columns is-desktop'>
          <StocksList
            stocks={this.state.stocks}
            stock_order = {this.state.stock_order}
            toggleStockSelection={this.toggleStockSelection}
            areStocksLoaded={this.areStocksLoaded}
            refreshed={this.state.refreshed}
            total_val={this.state.total_val}
            total_pct={this.state.total_pct}
            total_change={this.state.total_change}
            total_profit={this.state.total_profit}
            total_profit_pct={this.state.total_profit_pct}
          />
          <div className = 'column is-one-half'>
            <ShortsGraph
                key = {this.state.skey}
                resetCharts={this.resetCharts}
                loadAll={this.loadAll}
                state={this.state}

                />
            <PriceGraph
                key = {this.state.pkey}
                resetCharts={this.resetCharts}
                loadAll={this.loadAll}
                state={this.state}/>
            <br></br>
            <div class = "has-text-centered">
            <button className="button is-small" onClick={this.reload}>Reload</button>
            <button className="button is-small" style={{ marginLeft: '10px' }} onClick={this.props.setLoggedout}>Logout</button>
            </div>
            <br></br>
          </div>

        </div>
        <div className={ this.props.showSpinner ? 'modal is-active' : 'modal' }>
          <div className="modal-background"></div>
          <div className="modal-content">
            <StocksLoaderStatus connectionError={this.state.connectionError} />
          </div>
        </div>
      </div>
    )
  }

}
export default Dashboard;
