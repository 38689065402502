import React, { Component } from 'react';
import './App.css';

import Dashboard from './components/Dashboard.jsx';
import SimpleLogin from './components/SimpleLogin.jsx';
import UnsafeScriptsWarning from "./components/UnsafeScriptsWarning";
import cookie from 'react-cookies'

class App extends Component {

  state = {
    hasError: false,
    isLoggedIn: false,
    showSpinner: true
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    console.log('some error has occured');
    return { hasError: true };
  }

  componentDidMount() {
    // Check if user is already logged in from localStorage
    const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
    this.setState({ isLoggedIn });
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    console.log(error, info);
  }

  setLoggedin = () => {
    this.setState({ isLoggedIn: true });
  }

  setLoggedout = () => {
    localStorage.removeItem('isLoggedIn');
    this.setState({ isLoggedIn: false });
  }

  hideSpinner = () => {
    this.setState({showSpinner: false});
  }

  render() {
    if (this.state.hasError) {
      return <UnsafeScriptsWarning />;
    }

    if (!this.state.isLoggedIn) {
      return <SimpleLogin setLoggedin={this.setLoggedin} />;
    }

    return (
        <div>
            <div>
              <Dashboard hideSpinner={this.hideSpinner} showSpinner={this.state.showSpinner} setLoggedout={this.setLoggedout} />
            </div>
        </div>)
  }

}

export default App;
