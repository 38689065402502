import React, { useState } from 'react';
import * as bulma from "reactbulma";

const SimpleLogin = ({ setLoggedin }) => {
  const [password, setPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  // This is a simple hash function for demonstration purposes
  // In a real application, you would use a more secure hashing algorithm
  const simpleHash = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = ((hash << 5) - hash) + char;
      hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
  };

  // Replace this with your password hash
  // You can generate this by running: console.log(simpleHash('your-password'))
  // and then hardcoding the result here
  const CORRECT_PASSWORD_HASH = -1159962649; // Hash for "testpassword"

  const handleSubmit = (e) => {
    e.preventDefault();

    if (simpleHash(password) === CORRECT_PASSWORD_HASH) {
      // Store authentication in localStorage with no expiration
      // This will make the login persistent until explicitly logged out
      localStorage.setItem('isLoggedIn', 'true');

      // Also store in sessionStorage as a backup
      sessionStorage.setItem('isLoggedIn', 'true');

      // Set a cookie that doesn't expire for even more persistence
      document.cookie = "isLoggedIn=true; path=/; max-age=31536000"; // 1 year

      setLoggedin();
    } else {
      setErrorMsg('Incorrect password. Please try again.');
    }
  };

  return (
    <div className='container has-text-centered'>
      <div className="box" style={{ maxWidth: '400px', margin: '0 auto', marginTop: '50px' }}>
        <h3 className="title is-4">Quick Login</h3>
        <form onSubmit={handleSubmit}>
          <div className="field">
            <div className="control">
              <input
                className="input"
                type="password"
                placeholder="Enter password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoFocus
              />
            </div>
          </div>

          <div className="field">
            <div className="control">
              <button className="button is-primary is-fullwidth" type="submit">
                Login
              </button>
            </div>
          </div>

          {errorMsg && (
            <p className="has-text-danger">{errorMsg}</p>
          )}
        </form>
      </div>
    </div>
  );
};

export default SimpleLogin;
